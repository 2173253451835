const breakpoints = {
  width: {
    phone: '600px',
    midPhone: '750px',
    tablet: '1000px',
    laptop: '1240px',
    desktop: '1440px',
    wide: '1920px',
  },
  height: {
    xs: '200px',
    s: '400px',
    m: '600px',
    ml: '650px',
    l: '800px',
    xl: '1000px',
    xxl: '1200px',
  },
}

export default breakpoints
