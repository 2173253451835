import {useEffect, useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'

export const useFeedbackQuery = () => {
  const {wpGfForm, loading} = useStaticQuery(
    graphql`
      query getForm {
        wpGfForm(databaseId: {eq: 5}) {
          formFields {
            nodes {
              databaseId
              type
              ... on WpTextField {
                databaseId
                type
                label
              }
              ... on WpRadioField {
                choices {
                  text
                  value
                }
                isRequired
                label
                databaseId
              }
            }
          }
          confirmations {
            message
          }
          submitButton {
            text
            type
          }
          databaseId
        }
      }
    `,
  )

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  return {wpGfForm, isLoading}
}
