import React from 'react'
import styled from '@emotion/styled'
import {breakpoints, colors, typeHeight, typeScale} from '../utils'

const FeedbackText = ({
  field,
  checkboxes,
  checkedBoxes,
  handleCheckboxChange,
}) => {
  return (
    <>
      <FormQuestion htmlFor={field.label}>{'1. ' + field.label}</FormQuestion>

      {checkboxes.map(checkbox => (
        <InputContainer key={checkbox}>
          <Input
            type="checkbox"
            id={checkbox}
            name={checkbox}
            value={checkbox}
            checked={checkedBoxes.includes(checkbox)}
            onChange={handleCheckboxChange}
          />

          <Label htmlFor={checkbox}>{checkbox}</Label>
        </InputContainer>
      ))}
    </>
  )
}

const FormQuestion = styled.label`
  color: ${colors.white};
  display: inline-block;
  font-size: ${typeScale.button};
  font-weight: 900;
  line-height: ${typeHeight.formTitle};
  margin-bottom: 25px;

  @media (min-width: ${breakpoints.width.midPhone}) {
    font-size: ${typeScale.formTitle};
    line-height: ${typeHeight.h4};
  }
`

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.width.midPhone}) {
    margin-bottom: 15px;
  }
`

const Label = styled.label`
  font-size: ${typeScale.footer};
  line-height: ${typeHeight.formMedium};

  @media (min-width: ${breakpoints.width.midPhone}) {
    font-size: ${typeScale.body};
    line-height: ${typeHeight.body};
  }
`

const Input = styled.input`
  background: ${props => props.checked && colors.blue};
  border-color: ${colors.white};
`

export default FeedbackText
