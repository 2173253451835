import React from 'react'
import styled from '@emotion/styled'
import {breakpoints, colors, typeHeight, typeScale} from '../utils'

const FeedbackRadioFull = ({choice, field, howLikely, handleRadioChange}) => {
  return (
    <ContainerChoices>
      <Input
        id={choice.value + field.databaseId}
        type="radio"
        name={`field-${field.databaseId}`}
        value={choice.value}
        checked={howLikely === choice.value}
        onChange={() => handleRadioChange(choice.value, field.databaseId)}
      />

      <Label htmlFor={choice.value + field.databaseId}>{choice.text}</Label>
    </ContainerChoices>
  )
}

const ContainerChoices = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.width.midPhone}) {
    margin-bottom: 15px;
  }
`

const Label = styled.label`
  font-size: ${typeScale.footer};
  line-height: ${typeHeight.formMedium};

  @media (min-width: ${breakpoints.width.midPhone}) {
    font-size: ${typeScale.body};
    line-height: ${typeHeight.body};
  }
`

const Input = styled.input`
  background: ${props => props.checked && colors.blue};
  border-color: ${colors.white};
`

export default FeedbackRadioFull
