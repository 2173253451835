import React from 'react'
import FeedbackImage from '../../static/survey-icon.svg'
import styled from '@emotion/styled'
import {breakpoints, colors, typeHeight, typeScale} from '../utils'

const FeedBackButton = ({handleModal, openForm, handleIsClosedOnYes}) => {
  return (
    <>
      {openForm ? (
        <Modal
          isOpen={true}
          onClose={handleModal}
          title=""
          size="medium"
          handleIsClosedOnYes={handleIsClosedOnYes}
        >
          {/* Render Form OnClick*/}
        </Modal>
      ) : (
        <div
          role="button"
          aria-roledescription="button"
          style={{cursor: 'pointer'}}
          onClick={handleModal}
        >
          <FeedbackImg src={FeedbackImage} />
        </div>
      )}
    </>
  )
}

function Modal({
  isOpen,
  onClose,
  title,
  children,
  size,
  disableCloseOnOutsideClick,
  handleIsClosedOnYes,
}) {
  const handleOverlayClick = event => {
    if (!disableCloseOnOutsideClick && event.target === event.currentTarget) {
      onClose()
    }
  }

  return (
    <ModalContainer
      className={`modal ${isOpen ? 'open' : ''}`}
      onClick={handleOverlayClick}
    >
      <ModalDialog className={`modal-dialog ${size}`}>
        <Title>Do you have a minute to take a survey?</Title>

        <div className="modal-content">
          <ButtonContainer>
            {title && <h5 className="modal-title">{title}</h5>}

            <Button
              name="take-survey"
              type="submit"
              onClick={handleIsClosedOnYes}
            >
              Yes
            </Button>

            <ButtonSecondary
              name="decline-survey"
              type="submit"
              onClick={onClose}
            >
              Maybe Later
            </ButtonSecondary>
          </ButtonContainer>

          <div className="modal-body">{children}</div>
        </div>
      </ModalDialog>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  @media (min-width: ${breakpoints.width.tablet}) {
    margin-top: 50px;
  }
`

const ModalDialog = styled.div`
  margin: 0 10px;
`

const Title = styled.h1`
  font-size: ${typeScale.formTitle};
  color: ${colors.white};
  line-height: ${typeHeight.formTitle};
  padding: 30px 0 40px;
  text-align: center;

  @media (min-width: ${breakpoints.width.phone}) {
    margin-left: 20px;
    text-align: left;
  }

  @media (min-width: ${breakpoints.width.tablet}) {
    font-size: ${typeScale.h3};
    line-height: ${typeHeight.h3};
  }

  @media (min-width: ${breakpoints.width.laptop}) {
    font-size: ${typeScale.h2};
    line-height: ${typeHeight.h2};
  }
`

const FeedbackImg = styled.img`
  height: 55px;
  width: 55px;

  @media (min-width: ${breakpoints.width.laptop}) {
    height: 70px;
    width: 70px;
  }
`

const ButtonContainer = styled.div`
  margin: 0 auto 20px;
  width: 80%;

  @media (min-width: ${breakpoints.width.laptop}) {
    align-items: center;
    display: flex;
    margin: 0;
    margin-left: 20px;
    width: 100%;
  }
`

const Button = styled.button`
  background-color: ${colors.blue};
  border: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: ${typeScale.button};
  font-weight: 900;
  line-height: ${typeHeight.button};
  height: fit-content;
  padding: 15px 8px;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: ${breakpoints.width.tablet}) {
    padding: 23px 60px;
    width: 100%;
  }

  @media (min-width: ${breakpoints.width.laptop}) {
    padding: 23px 30px;
    width: calc(50% - 40px);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.lightGreen};
  }
`

const ButtonSecondary = styled(Button)`
  background-color: ${colors.OJ};
  margin-top: 20px;

  @media (min-width: ${breakpoints.width.laptop}) {
    margin-left: 40px;
    margin-top: 0;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.lightOrange};
    color: ${colors.OJ};
  }
`

export default FeedBackButton
