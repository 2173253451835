const colors = {
  blue: '#00BBD6',
  dark: '#000000',
  darkBlue: '#0C4C69',
  grey: '#577482',
  lightBlue: '#E7EEF7',
  menuBg: 'rgba(130, 72, 134)',
  purplePrimary: '#690C68',
  purpleSecondary: '#A32B8F',
  teal: '#007988',
  white: '#FFFFFF',
  heroGrey: '#F6F6F7',
  heroLinearGrey: 'linear-gradient(357.12deg, #97A5A6 0%, #FFFFFF 100%)',
  lightOrange: '#F4D8B7',
  lightBeige: '#F4EEE7',
  lightGreen: '#17AC95',
  OJ: '#F48300',
  someNewColorForFormText: '#F6B56A'
}

export default colors
