import React from 'react'
import {motion} from 'framer-motion'
import styled from '@emotion/styled'
import {colors, typeScale} from '../utils'

const CheckMarkContainer = styled.div`
  text-align: center;

  svg {
    width: 62px;
    height: 62px;
    max-width: 100%;
    margin-bottom: 18px;
  }
`
const SuccessMessage = () => {
  return (
    <CheckMarkContainer>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 53.19 53.19"
        initial={{
          opacity: 0,
          pathLength: 0,
        }}
        animate={{
          opacity: 1,
          pathLength: 1,
        }}
        transition={{
          delay: 0.1,
          duration: 0.6,
        }}
      >
        <g>
          <circle
            stroke={colors.blue}
            strokeWidth={1}
            fill="transparent"
            cx={26.59}
            cy={26.59}
            r={20}
          />
          <path
            stroke={colors.blue}
            strokeWidth={3}
            strokeLinecap="round"
            fill="transparent"
            d="M 16 27 L 23 34 L 40 17"
          />
        </g>
      </motion.svg>

      <Success initial={{y: 30, opacity: 0}} animate={{y: 0, opacity: 1}}>
        Thank you for your feedback
      </Success>
    </CheckMarkContainer>
  )
}

const Success = styled(motion.p)`
  font-size: ${typeScale.h4};
`

export default SuccessMessage
