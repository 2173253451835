const typeScale = {
  h1: '96px',
  h2: '50px',
  h3: '36px',
  h4: '24px',
  body: '20px',
  button: '18px',
  formTitle: '22px',
  footer: '16px',
  formMedium: '30px',
}

const typeHeight = {
  h1: '120px',
  h2: '64px',
  h3: '42px',
  h4: '36px',
  body: '28px',
  button: '24px',
  formTitle: '26px',
  formMedium: '20px',
}

export {typeHeight, typeScale}

// POTENTIAL IDEA FOR SLIDING SCALE FONTS
// In this example, the calculateFontSizes function takes the viewport width as an argument, and scales the font sizes based on whether the viewport is wider than the tablet breakpoint. You can then call this function when the viewport is resized, and use the scaled font sizes to update your CSS.

// function calculateFontSizes(viewportWidth) {
//   let scaledTypeScale = {};
//   const baseFontSize = parseInt(typeScale.body);

//   for (const [key, value] of Object.entries(typeScale)) {
//     const fontSize = parseInt(value);
//     const scaleFactor = (viewportWidth >= parseInt(breakpoints.tablet)) ? 1.2 : 1;
//     const scaledFontSize = Math.round(baseFontSize * scaleFactor ** (fontSize / baseFontSize));
//     scaledTypeScale[key] = `${scaledFontSize}px`;
//   }

//   return scaledTypeScale;
// }

// // Call the function when the viewport is resized
// window.addEventListener('resize', () => {
//   const scaledTypeScale = calculateFontSizes(window.innerWidth);
//   // Use the scaled font sizes to update your CSS
//   // For example:
//   document.body.style.fontSize = scaledTypeScale.body;
// });
