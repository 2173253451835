import React, {useState} from 'react'
import styled from '@emotion/styled'
import {breakpoints} from '../utils'
import Close from '../../static/close.svg'
import FeedBackButton from './feedback-button'

const FeedbackSkew = ({
  children,
  containerImage,
  toggleVisible,
  handleIsClosedOnYes,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModal = () => {
    setIsModalOpen(val => !val)
  }

  return (
    <>
      {isModalOpen ? (
        <ContainerOpen containerImage={containerImage} isOpen={isModalOpen}>
          <CloseButtonOpen
            role="button"
            aria-roledescription="button"
            onClick={toggleVisible}
          >
            <img src={Close}></img>
          </CloseButtonOpen>

          {children}

          <FeedBackButton
            openForm={isModalOpen}
            handleModal={handleModal}
            handleIsClosedOnYes={handleIsClosedOnYes}
          />
        </ContainerOpen>
      ) : (
        <Container containerImage={containerImage} isOpen={isModalOpen}>
          <CloseButton
            role="button"
            aria-roledescription="button"
            onClick={toggleVisible}
            tabindex="-1"
          >
            <img src={Close}></img>
          </CloseButton>

          {children}

          <FeedBackButton openForm={isModalOpen} handleModal={handleModal} />
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-image: ${props => `url(${props.containerImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 20px;
  position: fixed;
  right: 20px;
  padding: 20px 25px;

  @media (min-width: ${breakpoints.width.tablet}) {
    bottom: 50px;
    padding: 20px 20px 22px 15px;
    padding: 20px 35px 25px 28px;
    right: 80px;
  }
`

const ContainerOpen = styled(Container)`
  background-position: left -1% bottom -1%;
  background-size: cover;
  min-height: 380px;
  padding: 40px 25px 20px;
  right: 10px;
  width: 70%;

  @media (min-width: ${breakpoints.width.phone}) {
    max-height: 410px;
    max-width: 410px;
    width: 60%;
  }

  @media (min-width: ${breakpoints.width.tablet}) {
    bottom: -65px;
    max-width: 550px;
    max-height: unset;
    min-height: 510px;
    width: 50%;
  }
`

// toDo Find image that is white and remove filter
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 5px;

  @media (min-width: ${breakpoints.width.tablet}) {
    right: 12px;
    top: 8px;
  }

  > img {
    filter: brightness(0) invert(1);
    height: 20px;
    width: 20px;

    @media (min-width: ${breakpoints.width.tablet}) {
      height: 25px;
      width: 25px;
    }
  }
`

const CloseButtonOpen = styled(CloseButton)`
  right: 20px;
  top: 36px;

  @media (min-width: ${breakpoints.width.phone}) {
    right: 22px;
    top: 10px;
  }

  @media (min-width: ${breakpoints.width.tablet}) {
    right: 30px;
    top: 20px;
  }

  > img {
    @media (min-width: ${breakpoints.width.tablet}) {
      height: 25px;
      width: 25px;
    }
  }
`

export default FeedbackSkew
