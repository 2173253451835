import React, {useState} from 'react'
import FeedbackSkew from './feedback-skew'
import PurpleSkew from '../../static/skew-purple.svg'
import FeedbackModal from './feedback-modal'

const FeedBackForm = ({children}) => {
  const [isVisible, setIsVisible] = useState(true)
  const [isClosedOnYes, setIsClosedOnYes] = useState('')

  const toggleVisible = () => {
    setIsVisible(!isVisible)
  }

  const toggleIsClosedOnYes = () => {
    if (isClosedOnYes == '') {
      setIsClosedOnYes('true')
    }
  }

  const toggleModal = () => {
    setIsClosedOnYes('')
  }

  return (
    <>
      {isClosedOnYes !== 'true' ? (
        <div className={isVisible ? 'visible' : ''}>
          {isVisible ? (
            <FeedbackSkew
              toggleVisible={toggleVisible}
              containerImage={PurpleSkew}
              handleIsClosedOnYes={toggleIsClosedOnYes}
            ></FeedbackSkew>
          ) : (
            ''
          )}

          {children}
        </div>
      ) : (
        <FeedbackModal
          isOpen={isClosedOnYes === 'true'}
          handleCloseModal={toggleModal}
          handleCloseOnceAndForAll={toggleModal}
        />
      )}
    </>
  )
}

export default FeedBackForm
