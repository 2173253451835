import React from 'react'
import styled from '@emotion/styled'
import {breakpoints, colors, typeHeight, typeScale} from '../utils'

const FeedbackRadio = ({
  field,
  handleRadioChange,
  knowledgeAfter,
  knowledgeBefore,
  id,
}) => {
  return (
    <>
      <div className="half-width" key={field.databaseId}>
        {field.databaseId === 8 ? (
          <FormQuestion htmlFor={field.label}>
            <strong>BEFORE</strong> seeing the campaign?
          </FormQuestion>
        ) : field.databaseId === 16 ? (
          <FormQuestion htmlFor={field.label}>
            <strong>AFTER</strong> seeing the campaign?
          </FormQuestion>
        ) : null}

        {field.choices.map(choice =>
          id === 8 ? (
            <ContainerChoices key={choice.value}>
              <Input
                id={choice.value + field.databaseId}
                type="radio"
                name={`field-${field.databaseId}`}
                value={choice.value}
                checked={knowledgeBefore === choice.value}
                onChange={() =>
                  handleRadioChange(choice.value, field.databaseId)
                }
              />

              <Label htmlFor={choice.value + field.databaseId}>
                {choice.text}
              </Label>
            </ContainerChoices>
          ) : id === 16 ? (
            <ContainerChoices key={choice.value}>
              <Input
                id={choice.value + field.databaseId}
                type="radio"
                name={`field-${field.databaseId}`}
                value={choice.value}
                checked={knowledgeAfter === choice.value}
                onChange={() =>
                  handleRadioChange(choice.value, field.databaseId)
                }
              />

              <Label htmlFor={choice.value + field.databaseId}>
                {choice.text}
              </Label>
            </ContainerChoices>
          ) : null,
        )}
      </div>
    </>
  )
}

const FormQuestion = styled.label`
  color: ${colors.white};
  display: inline-block;
  font-size: ${typeScale.body};
  font-weight: 300;
  line-height: ${typeHeight.body};
  margin-bottom: 25px;

  strong {
    color: ${colors.someNewColorForFormText};
    font-weight: 300;
  }

  @media (min-width: ${breakpoints.width.midPhone}) {
    font-size: ${typeScale.h4};
    line-height: ${typeHeight.h4};
  }
`

const ContainerChoices = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.width.midPhone}) {
    margin-bottom: 15px;
  }
`

const Input = styled.input`
  background: ${props => props.checked && colors.blue};
  border-color: ${colors.white};
`

const Label = styled.label`
  font-size: ${typeScale.footer};
  line-height: ${typeHeight.formMedium};

  @media (min-width: ${breakpoints.width.midPhone}) {
    font-size: ${typeScale.body};
    line-height: ${typeHeight.body};
  }
`

export default FeedbackRadio
