// FeedBackFormWrapper.js
import React from 'react'
import FeedBackForm from '../components/feed-back-form'

const FeedBackFormWrapper = ({children}) => (
  <>
    {children}
    <FeedBackForm />
  </>
)

export default FeedBackFormWrapper
