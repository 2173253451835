import React, {useState} from 'react'
import styled from '@emotion/styled'
import ReactModal from 'react-modal'
import {useFeedbackQuery} from '../graphql-hooks/feedback'
import {motion} from 'framer-motion'
import {breakpoints, colors, typeHeight, typeScale} from '../utils'
import SuccessMessage from './success-message-component'
import Close from '../../static/menu-close.svg'
import FeedbackText from './feedback-text'
import FeedbackRadio from './feedback-radio'
import FeedbackRadioFull from './feedback-radio-full'

ReactModal.setAppElement('#___gatsby')

const FeedbackModal = ({
  handleCloseModal,
  handleCloseOnceAndForAll,
  isOpen,
}) => {
  const {wpGfForm} = useFeedbackQuery()
  const formData = wpGfForm
  const [knowledgeBefore, setKnowledgeBefore] = useState('')
  const [knowledgeAfter, setKnowledgeAfter] = useState('')
  const [howLikely, setHowLikely] = useState('')
  const [checkedBoxes, setCheckedBoxes] = useState([])
  const [finishHimNoMercy, setFinishHimNoMercy] = useState('')
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)
  const [errorMessageVisible, setErrorMessageVisible] = useState(false)
  const [isResponseLoading, setIsResponseLoading] = useState(false)

  const checkboxes = [
    'I saw an ad on social media',
    'I saw an ad on a website',
    'I saw an ad at a bus stop',
    'I saw an ad on a billboard',
    'I saw an ad at the airport',
    'I saw an ad on the SkyTrain or at a SkyTrain station',
    'I saw it on a poster in a restaurant/bar/pub',
    'A friend or family member recommended it',
    'Someone shared the link on social media',
    'I came here through a search engine',
    'None of the above',
  ]

  const formId = 5
  const endpoint = `https://bccancer2.wpengine.com/wp-json/gf/v2/forms/${formId}/submissions`
  const username = 'ck_445b730ceb6860ee83eb2d3ebc815313fdadd3fc'
  const password = 'cs_587d264fdb555540cc2b43bed69a362e5e954f33'

  const handleCheckboxChange = event => {
    const {value, checked} = event.target

    if (checked) {
      setCheckedBoxes([...checkedBoxes, value])
      setFinishHimNoMercy(
        finishHimNoMercy ? `${finishHimNoMercy}, ${value}` : value,
      )
    } else {
      setCheckedBoxes(checkedBoxes.filter(box => box !== value))
      setFinishHimNoMercy(
        finishHimNoMercy.replace(new RegExp(`,? ${value},?`), ''),
      )
    }
  }

  const handleRadioChange = (value, id) => {
    if (id === 8) {
      setKnowledgeBefore(value)
    } else if (id === 16) {
      setKnowledgeAfter(value)
    } else {
      setHowLikely(value)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    setIsResponseLoading(true)

    const formData = {
      input_20: finishHimNoMercy,
      input_8: knowledgeBefore,
      input_16: knowledgeAfter,
      input_17: howLikely,
    }

    if (
      !knowledgeBefore ||
      !knowledgeAfter ||
      !howLikely ||
      checkedBoxes.length === 0
    ) {
      setIsResponseLoading(false)
      setErrorMessageVisible(true)
      return
    } else {
      setIsResponseLoading(true)
      setErrorMessageVisible(false)
    }

    const headers = new Headers()

    headers.append('Authorization', 'Basic ' + btoa(username + ':' + password))
    headers.append('Content-Type', 'application/json')

    fetch(endpoint, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(() => {
        setErrorMessageVisible(false)
        setSuccessMessageVisible(true)
      })
      .catch(error => console.error(error))
      .finally(() => {
        setIsResponseLoading(false)
      })
  }

  return (
    <ReactModal
      bodyOpenClassName="body-feedback__opened"
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      portalClassName="modal-feedback"
      preventScroll={false}
      shouldCloseOnEsc={true}
    >
      <Image
        src={Close}
        alt=""
        role="button"
        aria-roledescription="button"
        onClick={handleCloseModal}
      />

      <Form onSubmit={handleSubmit}>
        <>
          {formData?.formFields?.nodes.map(
            field =>
              field.type === 'TEXT' && (
                <FormSection key={field.databaseId}>
                  <FeedbackText
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    field={field}
                    checkedBoxes={checkedBoxes}
                  />
                </FormSection>
              ),
          )}

          <FormSection>
            <FormQuestion>
              2. On a scale of 1 to 5 (where 1 = poor and 5 = excellent), how
              would you rate your knowledge about the link between alcohol and
              cancer:
            </FormQuestion>

            <FormSectionFlex>
              {formData?.formFields?.nodes.map(field =>
                field.type === 'RADIO' &&
                (field.databaseId === 8 || field.databaseId === 16) ? (
                  <FeedbackRadio
                    key={field.databaseId}
                    field={field}
                    handleRadioChange={handleRadioChange}
                    knowledgeAfter={knowledgeAfter}
                    knowledgeBefore={knowledgeBefore}
                    id={field.databaseId}
                  />
                ) : null,
              )}
            </FormSectionFlex>
          </FormSection>

          {formData?.formFields?.nodes.map(
            field =>
              field.type === 'RADIO' &&
              field.databaseId === 17 && (
                <FormSection key={field.databaseId}>
                  <FormQuestion htmlFor={field.label}>
                    {'3. ' + field.label}
                  </FormQuestion>

                  {field.choices.map(choice => (
                    <FeedbackRadioFull
                      key={choice.value}
                      choice={choice}
                      field={field}
                      handleRadioChange={handleRadioChange}
                      howLikely={howLikely}
                    />
                  ))}
                </FormSection>
              ),
          )}

          <ButtonContainer>
            {errorMessageVisible ? (
              <>
                <ErrorMessage>
                  Please select an answer for all questions to submit
                </ErrorMessage>
              </>
            ) : successMessageVisible && !isResponseLoading ? (
              <SuccessMessage />
            ) : null}

            {!successMessageVisible && (
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isResponseLoading}
                className={
                  isResponseLoading
                    ? 'button__loading'
                    : errorMessageVisible
                    ? 'button__error'
                    : ''
                }
              >
                <motion.span
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 1}}
                  transition={{duration: 1}}
                >
                  {isResponseLoading
                    ? 'submitting...'
                    : errorMessageVisible
                    ? 'submit'
                    : 'submit'}
                </motion.span>
              </Button>
            )}
          </ButtonContainer>
        </>
      </Form>

      {successMessageVisible && (
        <SuccessButton
          onClick={handleCloseOnceAndForAll}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{duration: 1}}
        >
          <motion.span>close</motion.span>
        </SuccessButton>
      )}
    </ReactModal>
  )
}

const Form = styled.form`
  .half-width {
    width: 50%;

    &:first-of-type {
      margin-right: 10px;
    }
  }

  input:where([type='checkbox'], [type='radio']) {
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid ${colors.white};
    border-radius: 8px;
    cursor: pointer;
    height: 30px;
    margin-right: 10px;
    min-width: 30px;
    width: 30px;

    @media (min-width: ${breakpoints.width.midPhone}) {
      margin-right: 20px;
    }
  }

  input:where([type='checkbox'], [type='radio']):where(
      :active:not(:disabled),
      :focus
    ) {
    border-color: ${colors.blue};
    outline: none;
  }
`

const Image = styled.img`
  cursor: pointer;
  height: 28px;
  position: absolute;
  right: 20px;
  top: 30px;
  width: 28px;

  @media (min-width: ${breakpoints.width.tablet}) {
    right: 50px;
  }
`

const FormSection = styled.div`
  margin-bottom: 60px;
`

const FormSectionFlex = styled.div`
  display: flex;
`

const FormQuestion = styled.label`
  color: ${colors.white};
  display: inline-block;
  font-size: ${typeScale.button};
  font-weight: 900;
  line-height: ${typeHeight.formTitle};
  margin-bottom: 25px;

  @media (min-width: ${breakpoints.width.midPhone}) {
    font-size: ${typeScale.formTitle};
    line-height: ${typeHeight.h4};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const Button = styled(motion.button)`
  background-color: ${props =>
    props.className === 'button__loading'
      ? colors.OJ
      : props.className === 'button__error'
      ? colors.lightBlue
      : colors.blue};
  border: none;
  color: ${props =>
    props.className === 'button__loading'
      ? colors.purplePrimary
      : props.className === 'button__error'
      ? colors.purplePrimary
      : colors.white};
  cursor: pointer;
  font-size: ${typeScale.button};
  font-weight: 900;
  line-height: ${typeHeight.button};
  height: fit-content;
  margin: 30px auto;
  padding: 18px 60px;
  text-transform: uppercase;
  width: 85%;

  @media (min-width: ${breakpoints.width.tablet}) {
    margin: 60px auto 0;
    padding: 23px 60px;
    width: auto;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.lightOrange};
    color: ${colors.purplePrimary};
  }

  &:disabled {
    background-color: ${colors.lightOrange};
    cursor: pointer;
  }
`

const SuccessButton = styled(Button)`
  display: block;
  margin: 60px auto !important;
`

const ErrorMessage = styled.p`
  color: ${colors.lightOrange};
  font-family: Helvetica;
  font-size: 18px;
  height: 44px;
  left: 50%;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 20px;
  transform: translateX(-50%);
`

export default FeedbackModal
